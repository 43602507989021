(function () {
    $(function() {
        setMenuItemsToggleable();
        setActiveMenuItem();

        function setActiveMenuItem() {
            $('.item').each(function () {
                if(window.location.pathname.includes($(this).data('section'))) {
                    $(this).addClass('active');
                    toggleMenuItem($(this));
                }
            });

            $('.sub-item').each(function () {
                if(window.location.pathname == $(this).attr('href')) {
                    $(this).addClass('active');
                }
            });
        }

        function setMenuItemsToggleable() {
            $('.item').on('click', function (event) {
                toggleMenuItem($(this));
            });
        }

        function toggleMenuItem(menuItem) {
            const arrowRight = menuItem.find('.arrow-right');
            const arrowDown = menuItem.find('.arrow-down');

            if (arrowDown.html() != undefined) {
                toggleVisibility(arrowDown.get(0));
                toggleVisibility(arrowRight.get(0));
            }

            const section = (menuItem.data('section'));
            if (section) {
                $(`.sub-item[data-section='${section}']`).each(function (i, obj) {
                    toggleVisibility(obj);
                });
            }
        }

        function toggleVisibility(obj) {
            getDisplay(obj) != 'block' ? obj.style.display = 'block' : obj.style.display = 'none';
        }

        function getDisplay(obj) {
            return obj.currentStyle ? obj.currentStyle['display'] :
                window.getComputedStyle ? window.getComputedStyle(obj, null).getPropertyValue('display') : null;
        }
    })
}());
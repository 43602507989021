(function () {
    $(function() {
        // var loading = false;
        // var getTaskSyncImages = function () {
        //     $.ajax({
        //         type: "GET",
        //         url: "/admins/api/task_sync_images",
        //         success: function (data) {
        //             if (data.task != null) {
        //                 loading = true;

        //                 setTimeout(getTaskSyncImages, 500);
        //                 $("#image-syncer-progress").show();
        //                 $("#image-syncer-progress-bar-fill").css("width", data.task.progress_percent + "%");

        //                 if (data.task.progress_percent != null) {
        //                     $("#image-syncer-progress-bar-text").text(data.task.progress_percent + "%");
        //                 }

        //                 if (data.task.message != null) {
        //                     $("#image-syncing-title").text(data.task.message);
        //                 }

        //                 $("#image-syncer-action").hide();
        //             } else {
        //                 if (loading) {
        //                     location.reload();
        //                 }
        //                 setTimeout(getTaskSyncImages, 5000);
        //                 $("#image-syncer-progress").hide();
        //                 $("#image-syncer-action").show();
        //             }
        //         },
        //         error: function (error) {
        //             setTimeout(getTaskSyncImages, 5000);
        //         },
        //         async: true,
        //         contentType: 'application/json',
        //         timeout: 10
        //     });
        // }
        // setTimeout(getTaskSyncImages, 1000);
        $("#image-syncer-progress").hide();
        $("#image-syncer-action").hide();
    });
}());
(function () {
    $(function() {
        $(".drop-container").on('dragenter', function (e) {
            e.preventDefault();
            $(this).css('border', '#39b311 2px dashed');
            $(this).css('background', '#f1ffef');
        });

        $(".drop-container").on('dragover', function (e) {
            e.preventDefault();
        });

        $(".drop-container").on('drop', function (e) {
            $(this).css('border', '#07c6f1 2px dashed');
            $(this).css('background', '#FFF');
            e.preventDefault();

            var images = e.originalEvent.dataTransfer.files;
            for(var i = 0; i < images.length; i++) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    var imagePreview = $(".drop-image").clone();
                    imagePreview.attr("src", e.target.result);
                    imagePreview.removeClass("drop-image");
                    imagePreview.addClass("preview");
                    $('.drop-container').append(imagePreview);
                    $('.drop-container').append(
                        "<div class='text-block'>" + e.target.fileName + "</div>");
                    $('.drop-container').append("<div class='v-space-1'/>");
                }
                reader.fileName = images[i].name
                reader.readAsDataURL(images[i]);
            }
        });
    })
}());
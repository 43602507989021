(function () {
    $(function () {
        var uploadFilesButton = $('#uploadFilesButton')
        input = $('.fileURL')
        var files,
            file,
            extension,
            output = document.getElementById("fileOutput"),
            holder = document.getElementById("fileHolder"),
            uploadingStatus= document.getElementById("uploadingStatus");

        if(input == null) {
            return
        }

        for(var i = 0; i < input.length; i++) {
            input[i].addEventListener("change", function (e) {
                files = e.target.files;
                output.innerHTML = "";
                uploadingStatus.innerHTML = ""

                for (var i = 0, len = files.length; i < len; i++) {
                    file = files[i];
                    extension = file.name.split(".").pop();
                    output.innerHTML += "<li>" + file.name + "</li>";
                }

                if (files.length > 0) {
                    uploadFilesButton.css('display', 'block')
                }
            }, false);

            input[i].addEventListener("dragover", function (e) {
                holder.classList.add("highlightOver");
            });

            input[i].addEventListener("dragleave", function (e) {
                holder.classList.remove("highlightOver");
            });

            input[i].addEventListener("dragend", function (e) {
                holder.classList.remove("highlightOver");
            });

            input[i].addEventListener("drop", function (e) {
                holder.classList.remove("highlightOver");
            });
        }

        function uploadFile(fileIndex) {
            var sku = uploadFilesButton.attr('sellableItemSku')
            uploadFilesButton.css('display', 'none')

            if(fileIndex >= files.length) {
                uploadingStatus.innerHTML = "อัพโหลดเสร็จแล้ว กรุณารอ Refresh หน้า"
                $.post( "/admins/api/sync_s3?sku=" + sku, function() {
                    location.reload();
                })
                return
            }

            uploadingStatus.innerHTML = "กำลังอัพโหลดไฟล์ " + (fileIndex + 1) + " of " + files.length

            $.get("/admins/api/signed_url?key=sellable_images/" + sku + "/" + files[fileIndex].name, function (data) {
                var key = data.key
                var url = data.url
                $.ajax({
                    type: "PUT",
                    url: url,
                    success: function (data) {
                        uploadFile(fileIndex + 1)
                    },
                    error: function (error) {

                    },
                    data: files[fileIndex],
                    contentType: 'binary/octet-stream',
                    processData: false,
                });
            });

        }

        uploadFilesButton.on('click', function () {
            uploadFile(0)
        })
    })
}());